import React, { useEffect } from "react";
import Topbar from "../../components/Topbar";
import { getAlbums } from "./redux/service";
import useStyles from "./styles";

interface Props {}

const Gallery = (props: Props) => {
  const styles = useStyles();
  const addButtonEvent = () => {};

  useEffect(() => {
    getAlbums();
  }, []);

  return (
    <div className={styles.container}>
      <Topbar
        addButtonText="Add Photo"
        addButtonHandler={addButtonEvent}
        heading="Gallery"
      />
      <div></div>
    </div>
  );
};

export default Gallery;
