import { Folder } from "@mui/icons-material";
import { Checkbox, colors, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../../../../assets/contants";
import { RootState } from "../../../../../redux/store/store";

const useStyles = makeStyles((theme) => ({
  folderName: {
    marginLeft: theme.spacing(2),
  },
  headingWrapper: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #707070",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  icon: {
    color: colors.yellow[300],
    fontSize: 24,
  },
}));

export const FolderItem = (item: any) => {
  const { slipno, code } = useParams();
  const { selection } = useSelector((state: RootState) => state.album);
  const styles = useStyles();
  const { onImageTap } = item;
  return (
    <section id={item?.name}>
      {item.name && (
        <>
          <div className={styles.headingWrapper}>
            <Folder className={styles.icon} />
            <Typography
              variant="h6"
              component="h6"
              className={styles.folderName}
            >
              {item.name} ({item?.dir ? item?.dir?.length : "loading..."})
            </Typography>
          </div>
        </>
      )}
      <Container maxWidth={"xl"}>
        <Grid container spacing={1}>
          {item?.dir && (
            <>
              {Array.from(item?.dir).map((file: any, ffi) => {
                const url = `${baseUrl}/${slipno}/${code}/${item.name}/${file?.file}`;
                const folder = item?.name;
                const fname = file?.file;
                const selectionindex = selection.findIndex(
                  (x: any) => x?.url === url
                );
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={`${url}-${ffi}`}>
                    <img
                      src={url}
                      style={{
                        objectFit: "cover",
                        backgroundSize: "cover",
                        maxWidth: "100%",
                      }}
                      onClick={() => onImageTap(file.file, item.name)}
                    />
                    <div>
                      <Checkbox
                        id={`select-${url}`}
                        value={
                          selection.findIndex((x: any) => x.url === url) !== -1
                        }
                        checked={
                          selection.findIndex((x: any) => x.url === url) !== -1
                        }
                        onChange={(e) => {
                          item?.selectionEvent(e, url, folder, fname);
                        }}
                        name={`${item.name}-${file.file}`}
                      />
                      <label htmlFor={`select-${url}`}>
                        {fname?.length > 13 ? fname?.substr(0, 13) : fname}
                      </label>
                    </div>
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
      </Container>
    </section>
  );
};
