import { Alert, Snackbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AlertTriangle, CheckCircle, XOctagon } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../redux/reducers/snackbarSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export const GlobalSnackbar = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { snackbarOpen, snackbarType, message } = useSelector(
    (state) => state.snackbar
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: setSnackbar.type,
      payload: {
        snackbarOpen: false,
        snackbarType,
        message,
      },
    });
  };
  return (
    <div className={styles.root}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
      >
        <Alert
          elevation={6}
          variant={"filled"}
          onClose={handleClose}
          color={snackbarType}
          icon={
            snackbarType === "success" ? (
              <CheckCircle />
            ) : snackbarType === "error" ? (
              <XOctagon />
            ) : (
              <AlertTriangle />
            )
          }
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};
