import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { ChevronLeft } from "react-feather";

interface Props {
  open: boolean;
  onClose: () => void;
  selection: any;
}

const Title = styled(DialogTitle)({
  display: "flex",
  alignItems: "center",
});

export const ViewSelection = (props: Props) => {
  return (
    <Dialog open={props.open} onClose={props.onClose} fullScreen>
      <Title>
        <IconButton onClick={props.onClose}>
          <ChevronLeft />
        </IconButton>
        <Typography component="h6" variant="h6" color="#000">
          {props.selection?.length} Files
        </Typography>
      </Title>
      <DialogContent>
        <Container maxWidth={false}>
          <Grid container>
            {props?.selection?.map((item: any, fi: number) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={`selection-${fi}`} mb={2}>
                <img src={item.url} alt={`selection-${item.name}`} />
                <Typography component="h6" variant="h6">
                  {item?.name}
                </Typography>
                <Typography color="#000">Remark: {item?.remark ? item?.remark : "No Remarks"}</Typography>
              </Grid>
            ))}
          </Grid>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
