import { Preview, Save as SaveIcon } from "@mui/icons-material";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setActiveFolder } from "../../redux/slice";

const TopbarWrapper = styled("div")({
  width: "100%",
  position: "fixed",
  zIndex: 10,
  borderBottom: "1px solid #70707040",
  background: "#FFF",
});

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headingText: {
    display: "flex",
    flexDirection: "column",
  },
  ml2: {
    marginLeft: theme.spacing(2),
  },
}));

interface Props {
  albumInfo: any;
  activeFolder: string;
  albumData: any;
  selection?: any;
  saveSelection: any;
  originalSelection: any;
  toggleDialog: () => void;
}

export const Topbar = (props: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { albumInfo, albumData } = props;

  const handleChange = (e: SelectChangeEvent<string>) => {
    dispatch({
      type: setActiveFolder.type,
      payload: e.target.value,
    });
    const id = document.getElementById(e.target.value);
    console.log("element", id);
    id?.scrollTo(0, id.getBoundingClientRect().y);
  };

  return (
    <>
      <TopbarWrapper>
        <div className={styles.container}>
          <div className={styles.headingText}>
            <Typography component="h6" variant="h6">
              {albumInfo?.name}
            </Typography>
            <Typography>{albumInfo?.code}</Typography>
          </div>
          <div>
            <FormControl variant="outlined" style={{ color: "#000" }}>
              <InputLabel id="Select-folder">Select Folder</InputLabel>
              <Select
                label="Select Folder"
                style={{ color: "#000" }}
                labelId="Select-folder"
                value={props.activeFolder}
                onChange={handleChange}
              >
                <MenuItem value="select">Select folder</MenuItem>
                {albumData.map((x: any, index: number) => (
                  <MenuItem
                    value={x?.name}
                    key={`${x?.name}-${index}`}
                    color="#000"
                  >
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <Tooltip title="Save selection">
              <div style={{ display: "inline-block" }}>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={props.saveSelection}
                  disabled={props.originalSelection === props.selection}
                >
                  Save
                </Button>
              </div>
            </Tooltip>
            {props.selection && props.selection.length !== 0 && (
              <>
                <Tooltip title="View selection">
                  <div
                    style={{ display: "inline-block" }}
                    className={styles.ml2}
                  >
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<Preview />}
                      disabled={props.selection.length === 0}
                      onClick={props.toggleDialog}
                    >
                      View
                      {` ${props.selection.length} selected file${
                        props.selection.length > 1 ? "s" : ""
                      }`}
                    </Button>
                  </div>
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </TopbarWrapper>
    </>
  );
};
