import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import { JulieThemeProvider } from "./styles/theme/ThemeProvider";
import { GlobalSnackbar } from "./app/components/snackbar";
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <JulieThemeProvider>
        <GlobalSnackbar />
        <App />
      </JulieThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
