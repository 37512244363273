import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Note {
  id: number;
  title: string;
  description: string;
}

const initialState = {
  notes: new Array<Note>(),
  loading: false,
};

export const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<Note>) => {
      const note = action.payload;
      state.notes.push(note);
    },
    deleteNote: (state, action) => {
      state.notes = state.notes.filter((x) => x.id !== action.payload.id);
    },
  },
});

export const { add, deleteNote } = notesSlice.actions;
export default notesSlice.reducer;
