import React, {
  ChangeEvent,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import logo from "./logo.svg";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store/store";
import { add, Note } from "./redux/reducers/notesSlice";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Page from "./app/pages/Page";
import Home from "./app/pages/Home";
import { HomePage } from "./app/pages/HomePage/loadable";
import { Dashboard } from "./app/pages/Dashboard";
import Gallery from "./app/pages/Gallery";
import Settings from "./app/pages/Settings";
import { ViewAlbum } from "./app/pages/ViewAlbum";
import { AlbumDetails } from "./app/pages/AlbumDetails";
import { GlobalSnackbar } from "./app/components/snackbar";
import { baseUrl } from "./assets/contants";

const initNote: Note = {
  id: Date.now(),
  title: "",
  description: "",
};

function App() {
  const [note, setNote] = useState<Note>(initNote);
  const { notes, loading } = useSelector((state: RootState) => state.notes);
  const dispatch = useDispatch();

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setNote({ ...note, [name]: value });
  };

  const handleSave: React.MouseEventHandler<HTMLButtonElement> | undefined = (
    event
  ): void => {
    event.preventDefault();
    dispatch({ type: add.type, payload: note });
  };

  useEffect(() => {
    fetch(`${baseUrl}/test.php`)
      .then((data) => data.json())
      .then((result) => {
        console.log(result, "result");
      });
  }, []);

  useEffect(() => {
    console.log(notes, "notes");
  }, [notes]);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/view" element={<ViewAlbum />}>
            <Route path=":slipno/:code" element={<AlbumDetails />} />
          </Route>
          <Route path="/dashboard" element={<Dashboard />}>
            <Route path="gallery" element={<Gallery />} />
            <Route path="settings" element={<Settings />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
