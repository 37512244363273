import { configureStore } from "@reduxjs/toolkit";
import notesSlice from "../reducers/notesSlice";
import snackbarSlice from "../reducers/snackbarSlice";
import albumDetails from "../../app/pages/AlbumDetails/redux/slice";

export const store = configureStore({
  reducer: {
    notes: notesSlice,
    album: albumDetails,
    snackbar: snackbarSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
