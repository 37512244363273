import { colors } from "@mui/material";
import { createTheme, Theme } from "@mui/material/styles";
declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}
export default createTheme({
  palette: {
    background: {
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#ccc",
    },
    text: {
      primary: '#000000',
      secondary: '#00000070',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        style: {
          textTransform: "none",
        },
      },
      styleOverrides: {
        text: {
          textTransform: "uppercase",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: colors.common.black,
          "&.Mui-selected": {
            color: "#000",
          },
        },
      },
    },
  },
});
