import { styled } from "@mui/system";
import { lazyLoad } from "../../../utils/loadable";
import Spinner from "../../components/Spinner";

const LoadingWrapper = styled("div")({
  width: "100%",
  height: "100vh",
  display: "grid",
  placeItems: "center",
});

export const HomePage = lazyLoad(
  () => import("./index"),
  (module) => module.Home,
  {
    fallback: (
      <LoadingWrapper>
        <Spinner />
      </LoadingWrapper>
    ),
  }
);
