import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { Form } from "./components/form";

interface Props {}

export const ViewAlbum = (props: Props) => {
  const { code } = useParams();
  console.log("code", code);
  return <div>{!code ? <Form /> : <Outlet />}</div>;
};
