import React from 'react'


const Settings = () => {
    return (
        <div>
            worksasdfasdf
        </div>
    )
}

export default Settings
