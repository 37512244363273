import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";
import { navLinks } from "./navlinks";
import { useStyles } from "./styles";

const NavWrapper = styled("div")({
  width: 240,
  display: "flex",
  flexDirection: "column",
  height: "100vh",
  position: "fixed",
  zIndex: 10,
  top: 0,
  left: 0,
  borderRight: "1px solid #eee",
});

const LinksWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const LinkItem = (props: any) => {
  const styles = useStyles();
  return (
    <NavLink
      to={props.path}
      className={({ isActive }) =>
        clsx({ [styles.linkWrapper]: true, [styles.activeLink]: isActive })
      }
    >
      {/* <span>{props.icon}</span> */}
      <span>{props.name}</span>
    </NavLink>
  );
};

const Links = () => (
  <>
    {navLinks.map((link) => (
      <LinkItem {...link} key={link.name} />
    ))}
  </>
);

const Navbar = () => {
  return (
    <NavWrapper>
      <Typography component="h4" variant="h4">
        Julie Studio
      </Typography>
      <LinksWrapper>
        <Links />
      </LinksWrapper>
    </NavWrapper>
  );
};

export default Navbar;
