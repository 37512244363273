const apiUrl = "https://juliestudio.ephotopick.com/";
export default (url: string, method: string = "GET", content: any, body: any) =>
  fetch(apiUrl + url, {
    method,
    ...(content && { headers: content, body }),
  }).then((response) =>
    Promise.all([response.status, response.json()])
      .then(([code, body]) => ({ ...body }))
      .catch(function (err) {
        console.log("Fetch Error :-S", err);
      })
  );
