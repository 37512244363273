import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PayloadType {
  snackbarOpen: boolean;
  snackbarType: "success" | "error" | "warning" | "info";
  message: string;
}
const initialState: PayloadType = {
  snackbarOpen: false,
  snackbarType: "success",
  message: "",
};

const snackSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    setSnackbar: (state, action: PayloadAction<PayloadType>) => {
      console.log(action.payload, "called");
      state.snackbarOpen = action.payload.snackbarOpen;
      state.snackbarType = action.payload.snackbarType;
      state.message = action.payload.message;
    },
  },
});

export const { setSnackbar } = snackSlice.actions;

export const showSnackbar = (
  snackbarOpen: boolean,
  snackbarType: "success" | "error" | "warning" | "info" = "success",
  message: string
) => ({
  type: setSnackbar.type,
  payload: { snackbarOpen, snackbarType, message },
});

export default snackSlice.reducer;
