import {
  Button,
  Card,
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { ChangeEventHandler, FormEventHandler, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showSnackbar } from "../../../../redux/reducers/snackbarSlice";
import { verifyAlbum } from "../../AlbumDetails/redux/requests";
import { getAlbumDetails } from "../../AlbumDetails/redux/services";

const Wrapper = styled("div")({
  width: "100vw",
  height: "100vh",
  display: "grid",
  placeItems: "center",
});

const FormContainer = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(2),
}));

interface Props {}

export const Form = (props: Props) => {
  const [code, setCode] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
    setCode(event.target.value);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    console.log("submit", code);
    const res = await verifyAlbum(code);
    const { data } = res;
    if (!data) {
      dispatch(showSnackbar(true, "error", "404: Enter a valid album code"));
      return false;
    }
    if (data && data.length === 0) {
      dispatch(showSnackbar(true, "error", "404: Enter a valid album code"));
    }
    if (data.expired !== "1") {
      if (data.blocked == "0") {
        navigate(`/view/${data.slipno}/${code}`);
      }
    } else {
      dispatch(
        showSnackbar(true, "error", "ERR-400: Invalid or wrong album code")
      );
      return false;
    }
    dispatch(getAlbumDetails(code));
    // console.log(`/view/${gallery?.albumInfo.slipno}`);
  };

  return (
    <Wrapper>
      <Card>
        <FormContainer onSubmit={handleSubmit}>
          <Typography variant="h6" mb={3}>
            Enter album code
          </Typography>
          <FormControl variant="outlined" style={{ marginBottom: 24 }}>
            <InputLabel id="album-code">Album code</InputLabel>
            <OutlinedInput
              name="albumcode"
              label="Album code"
              placeholder="Album code"
              onChange={handleChange}
            />
          </FormControl>
          <Button
            size="large"
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </FormContainer>
      </Card>
    </Wrapper>
  );
};
