import { CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { showSnackbar } from "../../../redux/reducers/snackbarSlice";
import { RootState } from "../../../redux/store/store";
import { FolderItem } from "./components/folderitem";
import { Topbar } from "./components/topbar";
import { ViewPhoto } from "./components/viewphoto";
import { ViewSelection } from "./components/ViewSelection";
import {
  getAlbumDetails,
  getAlbumFiles,
  getSelectionData,
  saveSelectionInfo,
} from "./redux/services";
import { setSelection, toggleViewDialog } from "./redux/slice";

const AlbumWrapper = styled("div")({
  width: "100vw",
  height: "100vh",
  alignItems: "center",
  justifyContent: "space-between",
});

interface Props {}

export const AlbumDetails = (props: Props) => {
  const { slipno, code } = useParams();
  // open state for opening active photo
  const [open, setOpen] = useState<boolean>(false);
  const [activePhotoIndex, setPhotoIndex] = useState<number>(0);
  const dispatch = useDispatch();

  const {
    albumData,
    albumInfo,
    loading,
    selection,
    activeFolder,
    originalSelection,
    openViewDialog,
    previewData,
  } = useSelector((state: RootState) => state.album);

  useEffect(() => {
    if (code && slipno) {
      dispatch(getAlbumDetails(code));
      //   debugger;
      dispatch(getAlbumFiles(slipno, code));
      dispatch(getSelectionData(slipno, code));
    }
  }, [slipno, code]);

  useEffect(() => {
    console.log(albumInfo, "albumInfo here");
  }, [albumInfo]);

  const renderAlbum = (): ReactNode => {
    return (
      <>
        {!loading && albumData.length === 0 && (
          <Typography>No folders</Typography>
        )}
        {loading && <CircularProgress />}
        {!loading && albumData.length > 0 && (
          <>
            {albumData.map((item: any, ai) => {
              return (
                <FolderItem
                  onImageTap={viewImage}
                  selectionEvent={handleSelectionChange}
                  {...item}
                  key={`album-item${item?.name}-${ai}`}
                />
              );
            })}
          </>
        )}
      </>
    );
  };

  const handleSelectionChange = (
    e: ChangeEvent<HTMLInputElement>,
    url: string,
    folder: string,
    file: string
  ) => {
    const { name, value, checked } = e.target;
    console.log(name, value, checked);
    let list = [...selection];
    const index = list.findIndex((x: any) => x.url === url);
    if (index === -1) {
      list.push({
        url,
        folder,
        file,
        remark: "",
      });
      // forceUpdate();
    } else {
      list.splice(index, 1);
      // forceUpdate();
    }
    dispatch({ type: setSelection.type, payload: list });
    console.log(list);
  };

  const saveSectionHandler = () => {
    if (slipno && code && selection) {
      console.log("calling save");
      dispatch(saveSelectionInfo(slipno, code, selection));
    }
  };

  const handleButtons = (value: number) => {
    if (activePhotoIndex + value >= previewData.length) {
      dispatch(showSnackbar(true, "warning", "Already last image"));
      return false;
    }
    if (activePhotoIndex + value < 0) {
      dispatch(showSnackbar(true, "warning", "Already first image"));
      return false;
    }
    if (value) {
      setPhotoIndex(activePhotoIndex + value);
    }
  };

  const viewImage = (fileName: string, folderName: string) => {
    console.log(fileName, folderName, "item here");
    const previewIndex = previewData.findIndex(
      (x: any) => x?.file === fileName && x?.folder === folderName
    );
    console.log(previewIndex);
    if (previewIndex > -1) {
      setPhotoIndex(previewIndex);
      setOpen(true);
    }
  };

  return (
    <AlbumWrapper>
      <ViewPhoto
        open={open}
        onClose={() => setOpen(false)}
        previewData={previewData}
        activePhotoIndex={activePhotoIndex}
        handleButtons={handleButtons}
      />
      <ViewSelection
        open={openViewDialog}
        onClose={() =>
          dispatch({ type: toggleViewDialog.type, payload: false })
        }
        selection={selection}
      />
      <Topbar
        albumInfo={albumInfo}
        albumData={albumData}
        activeFolder={activeFolder}
        selection={selection}
        saveSelection={saveSectionHandler}
        originalSelection={originalSelection}
        toggleDialog={() =>
          dispatch({ type: toggleViewDialog.type, payload: true })
        }
      />
      <div style={{ paddingTop: 72 }}>{renderAlbum()}</div>
    </AlbumWrapper>
  );
};
