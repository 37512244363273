import {
  setSnackbar,
  showSnackbar,
} from "../../../../redux/reducers/snackbarSlice";
import {
  getFolders,
  getSelection,
  saveSelection,
  verifyAlbum,
} from "./requests";
import {
  setAlbumData,
  setAlbumDetails,
  setOriginalSelection,
  setPageLoading,
  setSelection,
} from "./slice";

export const getAlbumDetails = (code: any) => async (dispatch: any) => {
  try {
    const res = await verifyAlbum(code);
    console.log(res);
    if (res.data) {
      console.log(res.data, "gt data ");
      dispatch({ type: setAlbumDetails.type, payload: res.data });
      dispatch({ type: setPageLoading.type, payload: false });
    }
  } catch (e) {
    console.log(e);
  }
};

export const getAlbumFiles =
  (slip: any, code: any) => async (dispatch: any) => {
    try {
      //   debugger;
      const res = await getFolders(slip, code);
      console.log(res.data.filter((x: any) => x.file !== "selection.text"));
      dispatch({
        type: setAlbumData.type,
        payload: res.data
          ? res.data.filter((x: any) => x.file !== "selection.txt")
          : [],
      });
    } catch (e) {
      console.log(e);
    }
  };

export const getSelectionData =
  (slip: any, code: any) => async (dispatch: any) => {
    try {
      const res = await getSelection(slip, code);
      console.log(res.data);
      dispatch({
        type: setSelection.type,
        payload: res.data.length > 0 ? JSON.parse(res.data) : [],
      });
      dispatch({
        type: setOriginalSelection.type,
        payload: res.data.length > 0 ? JSON.parse(res.data) : [],
      });
    } catch (e) {
      console.log(e);
    }
  };

export const saveSelectionInfo =
  (slip: any, code: any, selection: any) => async (dispatch: any) => {
    try {
      const res = await saveSelection(slip, code, selection);
      // dispatch({
      //   type: setSelection.type,
      //   payload: res.data ? res.data : [],
      // });
      // dispatch({
      //   type: setOriginalSelection.type,
      //   payload: res.data ? res.data : [],
      // });
      // const event = showSnackbar(true, "success", "Seleciton saved");
      // console.log(event, "event");
      dispatch({
        type: setSnackbar.type,
        payload: {
          snackbarOpen: true,
          snackbarType: "success",
          message: "Selection saved",
        },
      });
    } catch (e) {
      console.log(e);
    }
  };
