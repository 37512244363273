import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  albumInfo: {},
  albumData: [],
  previewData: new Array(),
  selection: new Array(),
  originalSelection: new Array(),
  loading: true,
  activeFolder: "select",
  openViewDialog: false,
};

const albumDetailSlice = createSlice({
  name: "albumDetails",
  initialState,
  reducers: {
    setAlbumDetails: (state, action: PayloadAction<any>) => {
      state.albumInfo = action.payload;
      console.log(action.payload, "albuminfo");
    },
    setAlbumData: (state, action: PayloadAction<any>) => {
      state.albumData = action.payload;
      let data: any = [];
      let flatDir: any = [];
      let id = 1;
      for (let i of state.albumData) {
        console.log(i);
        const { dir, name } = i;
        flatDir = Array.from(dir).flatMap((file: any) => [
          {
            ...file,
            folder: name,
            id: id++,
          },
        ]);
        data = [...data, ...flatDir];
      }
      console.log(data);
      state.previewData = data;
    },
    setSelection: (state, action: PayloadAction<any>) => {
      state.selection = action.payload;
    },
    setOriginalSelection: (state, action: PayloadAction<any>) => {
      state.originalSelection = action.payload;
    },
    setPageLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
    resetState: (state) => {
      state = initialState;
    },
    setActiveFolder: (state, action: PayloadAction<any>) => {
      state.activeFolder = action.payload;
    },
    toggleViewDialog: (state, action: PayloadAction<any>) => {
      state.openViewDialog = action.payload;
    },
  },
});

export const {
  setAlbumDetails,
  setAlbumData,
  setSelection,
  setPageLoading,
  setActiveFolder,
  setOriginalSelection,
  toggleViewDialog,
} = albumDetailSlice.actions;

export default albumDetailSlice.reducer;
