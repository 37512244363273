import axios from "axios";
import { baseUrl } from "../../../../assets/contants";

export const verifyAlbum = (code: any) => {
  const url = `${baseUrl}/getalbumInfo.php`;
  return axios.post(url, JSON.stringify({ code }));
};

export const getFolders = async (slip: any, code: any) => {
  const url = `${baseUrl}/getFiles.php?slip=${slip}&code=${code}`;

  return await axios.get(encodeURI(url));
};

export const getSelection = async (slip: any, code: any) => {
  const url = `${baseUrl}/get-selection.php`;
  return axios.post(encodeURI(url), JSON.stringify({ slipno: slip, code }));
};

export const saveSelection = async (slip: any, code: any, selection: any) => {
  const url = `${baseUrl}/save-selection.php`;
  return axios.post(
    encodeURI(url),
    JSON.stringify({ slipno: slip, code, selection: JSON.stringify(selection) })
  );
};
