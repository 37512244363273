import { Routes, Route, Outlet } from "react-router-dom";
import Gallery from "../Gallery";
import MainContent from "./components/MainContent";
import Navbar from "./components/Navbar";

export const Dashboard = () => {
  return (
    <>
      <div>
        <Navbar />
        <MainContent>
          <Outlet />
        </MainContent>
      </div>
    </>
  );
};
