import { styled } from "@mui/system";
import React, { ReactNode } from "react";

const Wrapper = styled("div")({
  width: "100%",
  paddingLeft: 240,
  position: "relative",
  maxHeight: "100vh",
  overflowY: "auto",
});

interface Props {
  children?: ReactNode;
}

const MainContent = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};

export default MainContent;
