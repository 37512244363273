import { ThemeProvider } from "@mui/material";
import React, { } from "react";
import MuiTheme from "./mui";

export const JulieThemeProvider = (props: any) => {
  return (
    <>
      <ThemeProvider theme={MuiTheme}>{props.children}</ThemeProvider>
    </>
  );
};
