import { Button, Typography } from "@mui/material";
import { styled } from "@mui/system";
import TopbarProps from "../../types/Topbar";

const Wrapper = styled("div")(({ theme }) => ({
  width: `calc(100vw - 240px)`,
}));
const Container = styled("div")(({ theme }) => ({
  display: `flex`,
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(2)
}));

const Topbar = ({
  heading,
  addButtonText = "Add",
  addButtonHandler,
}: TopbarProps) => {
  return (
    <Wrapper>
      <Container>
        <Typography>{heading}</Typography>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={addButtonHandler}
          >
            {addButtonText}
          </Button>
        </div>
      </Container>
    </Wrapper>
  );
};

export default Topbar;
