import { CircularProgress, CircularProgressProps } from "@mui/material";
import React from "react";

const Spinner = (props: CircularProgressProps) => {
  return (
    <>
      <CircularProgress {...props} />
    </>
  );
};

export default Spinner;
