import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  linkWrapper: {
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    textTransform: "capitalize",
    textDecoration: "none",
    color: "#707070",
  },
  activeLink: {
    background: '#70707070',
    color: '#707070'
  }
}));
