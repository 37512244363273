import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/styles";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../../../../assets/contants";

interface Props {
  open: boolean;
  onClose: () => void;
  previewData: Array<any>;
  activePhotoIndex: number;
  handleButtons: (value: number) => void;
}

const StyledDialogContent = styled(DialogContent)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const ViewPhoto = (props: Props) => {
  const { code, slipno } = useParams();
  const photoItem = props.previewData[props.activePhotoIndex];
  console.log(photoItem);
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>
        <Typography color="#000">{photoItem?.file}</Typography>
      </DialogTitle>
      <StyledDialogContent>
        <IconButton onClick={() => props.handleButtons(-1)}>
          <ChevronLeft />
        </IconButton>
        <img
          alt="preview"
          src={`${baseUrl}/${slipno}/${code}/${photoItem?.folder}/${photoItem?.file}`}
          style={{
            objectFit: "cover",
            backgroundSize: "cover",
            maxWidth: "100%",
          }}
        />
        <IconButton onClick={() => props.handleButtons(1)}>
          <ChevronRight />
        </IconButton>
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
